<template>
  <v-card id="">
    <v-card-text class="d-flex">
      <v-row class="mt-1">
        <div class="ml-4 d-flex flex-column">
          <span class="mb-4">Comissões CNH</span>

          <div
            v-if="itemsTable.length > 0"
            class="d-flex items-center mb-2"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiCalendarAccountOutline }}
            </v-icon>
            <span>REFERÊNCIA: {{ computedFormatDateReference }}</span>
          </div>
        </div>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mr-2"
          label="Buscar por nome"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          class="mr-4 mb-4"
          :color="itemsTable.length > 0 ? 'success' : 'gray'"
          outlined
          @click="modalFilter = true"
        >
          <v-icon size="">
            {{ icons.mdiFilterVariant }}
          </v-icon>

          <span class="ml-2">Filtro</span>
        </v-btn>

        <v-btn
          v-show="itemsTable.length > 0"
          class="mr-4 mb-4"
          color="error"
          outlined
          @click="clearGrid"
        >
          <v-icon size="">
            {{ icons.mdiFilterVariantRemove }}
          </v-icon>

          <span class="ml-2">Limpar</span>
        </v-btn>

        <v-btn
          v-if="isLoadingExport"
          color="warning"
          class="ml-2"
          outlined
        >
          <v-progress-circular
            indeterminate
            color="gray"
            :size="25"
          >
          </v-progress-circular>
        </v-btn>

        <v-menu
          v-else
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              color="warning"
              class="ml-2"
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="30">
                {{ icons.mdiDownload }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in itemsListExport"
              :key="item"
              link
            >
              <v-list-item-title
                @click="exportCsv(item)"
                v-text="item"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-card-text>

    <v-data-table
      v-model="itemTableSelected"
      :headers="headers"
      :items="listOfFilteredItems"
      :loading="isLoadingTableData"
      class="text-no-wrap"
      loading-text="Carregando dados..."
      disable-sort
    >
      <template #[`item.name`]="{ item }">
        <div class="container-vacation">
          {{ item.name }}

          <div
            v-show="item.vacation"
            class="vacation"
          >
            {{ item.vacation ? 'Férias' : '' }}
          </div>
        </div>
      </template>

      <template v-slot:item.controlling_status="{ item }">
        <v-alert
          text
          style="font-size: 12px;"
          class="badge"
          :color="
            item.controlling_status === 'VALIDADO'
              ? 'success'
              : item.controlling_status === 'REGISTRADO'
                ? 'primary'
                : 'error'
          "
        >
          {{ item.controlling_status }}
        </v-alert>
      </template>

      <template v-slot:item.self="{ item }">
        #{{ item.self }}
      </template>

      <template v-slot:item.valor_vehicles="{ item }">
        {{
          item.valor_vehicles
            ? Number(item.valor_vehicles).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : 'R$ 0,00'
        }}
      </template>

      <template v-slot:item.commission="{ item }">
        {{
          item.commission
            ? Number(item.commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : 'R$ 0,00'
        }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon
          v-if="item.reference === loadingCircle ? false : true"
          medium
          class="me-2"
          @click="detailsItems(item)"
        >
          {{ icons.mdiEyeOutline }}
        </v-icon>
        <v-progress-circular
          v-if="item.reference === loadingCircle ? true : false"
          indeterminate
          :size="25"
          color="gray"
        ></v-progress-circular>
      </template>

      <template v-slot:no-data>
        <NoDataInterface />
      </template>
    </v-data-table>

    <v-dialog
      v-model="openModal"
      max-width="1600px"
    >
      <Details
        :key="vendor_name"
        :reference="reference"
        :vendor-name="vendor_name"
        :status-sales="statusSales"
        :vendor-cpf="vendor_cpf"
        :vacation-info="vacation"
        :commission="commission"
        :total-sales="totalSales"
        :number-sales="numberSales"
        :registration-total="registrationTotal"
        @updatedTable="updatedTable()"
        @close="closeModal"
      ></Details>
    </v-dialog>

    <v-dialog
      v-model="isLoadingValidate"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="purple"
        dark
      >
        <v-card-text>
          Aguarde, estamos validando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalFilter"
      max-width="600px"
      persistent
    >
      <FilterModal
        @close="modalFilter = false"
        @filteredData="$event => setTableData($event)"
        @filteredBody="$event => setBodyFilter($event)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import NoDataInterface from '@/components/charts/NoDataInterface.vue'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendarAccountOutline,
  mdiCheckDecagram,
  mdiCircleOffOutline,
  mdiDownload,
  mdiEyeOutline,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiMagnify,
  mdiSendCircleOutline,
  mdiTrendingUp,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import moment from 'moment'
import 'moment/locale/pt-br'
import Details from './Details.vue'
import FilterModal from './FilterModal.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    Details,
    FilterModal,
    NoDataInterface,
  },

  mixins: [messages],

  data() {
    return {
      bodyFilter: {},
      filterData: '',
      modalFilter: false,
      itemsListExport: ['Sintético', 'Analítico', 'DP'],
      statusSales: '',
      classNoDataText: '',
      noDataText: '',
      itemSearchDate: '',
      itemSearch: '',
      itemSearchStatus: '',
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],
      listStatus: ['REGISTRADO', 'VALIDADO', 'INCONSISTENTE'],
      companyList: [],
      salesListId: [],
      itemTableSelected: [],
      companyNameSelected: '',
      loadingCircle: '',
      vendor_name: '',
      reference: '',
      mode: '',

      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },

      vendorInfo: '',
      loading: false,
      dateMenu: false,
      openModal: false,
      isLoadingValidate: false,

      itemsTable: [],
      listVendors: [],
      itemsTableFilter: [],

      icons: {
        mdiTrendingUp,
        mdiEyeOutline,
        mdiMagnify,
        mdiCheckDecagram,
        mdiSendCircleOutline,
        mdiDownload,
        mdiCircleOffOutline,
        mdiCalendarAccountOutline,
        mdiFilterVariant,
        mdiFilterVariantRemove,
      },

      headers: [
        {
          text: 'FILIAL',
          value: 'region',
          sortable: false,
          align: '',
        },
        {
          text: 'LOJA',
          value: 'fantasy_name',
          sortable: false,
          align: '',
        },
        {
          text: 'FUNCIONÁRIO',
          value: 'name',
          sortable: false,
          align: '',
        },
        {
          text: 'COD. SELF',
          value: 'cod_self',
          sortable: false,
          align: '',
        },
        {
          text: 'NÍVEL',
          value: 'level',
          sortable: false,
          align: '',
        },
        {
          text: 'META ATUAL',
          value: 'goal',
          sortable: false,
          align: 'center',
        },
        {
          text: 'REALIZADO',
          value: 'total',
          sortable: false,
          align: 'center',
        },
        {
          text: 'VALOR EM VENDAS',
          value: 'valor_vehicles',
          sortable: false,
          align: 'center',
        },
        {
          text: 'COMISSÃO',
          value: 'commission',
          sortable: false,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'controlling_status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'AÇÕES',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      isLoadingTableData: false,
      isLoadingExport: false,
      vendor_cpf: '',
      vacation: '',
      commission: 0,
      totalSales: 0,
      numberSales: 0,
      registrationTotal: 0,

      dataToExport: [],
      itemsAnalytic: [],
      listOfFilteredItems: [],
    }
  },

  computed: {
    computedItemSearchDate() {
      return this.formatDate(this.itemSearchDate)
    },

    computedFormatDateReference() {
      const newReference = moment(this.bodyFilter.reference, 'YYYY-MM').format('MMM/YYYY').toUpperCase()

      return newReference
    },
  },

  watch: {
    itemTableSelected(select) {
      const items = []
      select.map(({ id }) => {
        items.push(id)
      })

      this.salesListId = items
    },

    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.companyList = localStorageSlim.get('listCompanies', { decrypt: true })

    this.noDataText = 'SELECIONE UM FILTRO'
    this.classNoDataText = 'colorWarning'
  },

  methods: {
    closeModal() {
      this.openModal = false

      this.vendor_name = ''
    },
    setTableData(data) {
      this.listOfFilteredItems = data
      this.itemsTable = data
    },
    setBodyFilter(body) {
      this.bodyFilter = body
    },
    clearGrid() {
      this.listOfFilteredItems = []
      this.itemsTable = []
    },
    resolveUserProgressVariant(progress) {
      const variant = {
        REGISTRADO: 'info',
        VALIDADO: 'success',
      }

      if (progress in variant) {
        return variant[progress]
      }

      // if (progress === 'VALIDADO') return 'success'
      // if (progress === 'REGISTRADO') return 'info'

      return 'error'
    },

    async validateSales() {
      this.isLoadingValidate = true

      const body = {
        controlling_status: 'VALIDADO',
        contracts_id: this.salesListId,
      }

      axiosIns
        .post('/api/v1/integration/contract/update_status', body)
        .then(res => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: `${res.data.data}`,
            timer: 4000,
          })

          this.itemTableSelected = []
          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao validar vendas!',
            text: error,
          })
        }).finally(() => {
          this.isLoadingValidate = false
        })
    },

    async getDataSalesInputSelected() {
      this.itemsTable = []
      this.isLoadingTableData = true
      this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
      this.classNoDataText = ''

      const body = {
        region: this.itemSearch,
        reference: this.itemSearchDate,
        controlling_status: this.itemSearchStatus,
        company: this.companyNameSelected,
      }

      await axiosIns.post('/api/v1/integration/contract/vendor_region', body).then(res => {
        this.itemsTable = res.data.data
        this.pageOptions.itemsPerPage = res.data.per_page
        this.pageOptions.pageCount = res.data.last_page
        this.isLoadingTableData = false
        this.mode = 'filter'
      })
    },

    async exportCsv(item) {
      const file = await this.getCsv(item)

      if (file) {
        saveAs(file)
      }
    },

    async getCsv(item) {
      this.isLoadingExport = true

      if (item === 'DP') {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        worksheet.columns = [
          { header: 'VALOR COMISSÃO', key: 'commission' },
          { header: 'Empresa', key: 'fantasy_name' },
          { header: 'Funcionário', key: 'name' },
        ]

        this.itemsTable?.map(({ commission, fantasy_name: fantasyName, name }) => {
          worksheet.addRow([Number(commission), fantasyName, name])
        })

        let blob = null
        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'Comissões - CNH', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })

        this.isLoadingExport = false

        // eslint-disable-next-line consistent-return
        return blob
      }

      if (item === 'Sintético') {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        worksheet.columns = [
          { header: 'LOJA', key: 'fantasy_name' },
          { header: 'FUNCIONÁRIO', key: 'name' },
          { header: 'REFERÊNCIA', key: 'reference' },
          { header: 'META', key: 'goal' },
          { header: 'VENDAS', key: 'total' },
          { header: 'VALOR COMISSÃO', key: 'commission' },
          { header: 'VALOR DE VENDAS', key: 'valor_vehicles' },
        ]

        this.itemsTable.map(({
          fantasy_name: fantasyName, name, reference, goal, total, commission, valor_vehicles: vehicleValues,
        }) => {
          worksheet.addRow([
            fantasyName,
            name,
            reference,
            Number(goal),
            Number(total),
            Number(commission),
            Number(vehicleValues),
          ])
        })

        let blob = null
        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'eventos-funcionários', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })

        this.isLoadingExport = false

        // eslint-disable-next-line consistent-return
        return blob
      }

      if (item === 'Analítico') {
        const body = {
          region: this.bodyFilter.region,
          reference: this.bodyFilter.reference,
          controlling_status: this.bodyFilter.controlling_status,
        }

        await axiosIns.post('/api/v1/integration/contract/controlling_report', body).then(res => {
          this.itemsAnalytic = res.data
        })

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        worksheet.columns = [
          { header: 'LOJA', key: 'fantasy_name' },
          { header: 'FUNCIONÁRIO', key: 'name' },
          { header: 'PROPOSTA/DIGITO ', key: 'proposal_number' },
          { header: 'CLIENTE', key: 'client_name' },
          { header: 'DATA BORDERO', key: 'date_bordero' },
          { header: 'STATUS DA PROPOSTA', key: 'contract_status' },
          { header: 'MODELO', key: 'vehicle_model' },
          { header: 'PLANO DE VENDA ', key: 'sales_plan' },
          { header: 'VALOR DA VENDA', key: 'valor_vehicles' },
          { header: 'PERCENTUAL APLICADO', key: 'percentage' },
          { header: 'VALOR COMISSÃO', key: 'commission' },
        ]

        this.itemsAnalytic.map(itemList => {
          worksheet.addRow([
            itemList.fantasy_name,
            itemList.name,
            `${itemList.proposal_number} ${itemList.proposal_digit}`,
            itemList.client_name,
            itemList.date_bordero,
            itemList.contract_status,
            itemList.vehicle_model,
            Number(itemList.valor_vehicles) < 0 ? 'Estornado' : itemList.sales_plan,
            Number(itemList.valor_vehicles),
            Number(itemList.percentage),
            Number(itemList.commission),
          ])
        })

        let blob = null
        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'eventos-funcionários', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })

        this.isLoadingExport = false

        // eslint-disable-next-line consistent-return
        return blob
      }

      return ''
    },

    async getListTargetVendors() {
      this.isLoadingTableData = true

      await axiosIns.post('/api/v1/integration/contract/vendor_region').then(res => {
        this.itemsTable = res.data.data
        this.pageOptions.itemsPerPage = res.data.per_page
        this.pageOptions.pageCount = res.data.last_page
        this.isLoadingTableData = false
        this.mode = 'general'
      }).catch(() => {
        this.isLoadingTableData = false
      })
    },

    async updatePage() {
      this.isLoadingTableData = true
      this.itemsTable = []

      if (this.mode === 'general') {
        await axiosIns.post(`/api/v1/integration/contract/vendor_region?page=${this.pageOptions.page}`).then(res => {
          this.itemsTable = res.data.data
          this.pageOptions.itemsPerPage = res.data.per_page
          this.pageOptions.pageCount = res.data.last_page
        }).catch(error => {
          console.error(error.message)
        }).finally(() => {
          this.isLoadingTableData = false
        })

        return
      }

      const body = {
        region: this.itemSearch,
        reference: this.itemSearchDate,
      }

      await axiosIns
        .post(`/api/v1/integration/contract/vendor_region?page=${this.pageOptions.page}`, body)
        .then(res => {
          this.itemsTableFilter = res.data.data
          this.pageOptions.itemsPerPage = res.data.per_page
          this.pageOptions.pageCount = res.data.last_page
          this.mode = 'filter'
        }).catch(error => {
          console.error(error.message)
        }).finally(() => {
          this.isLoadingTableData = false
        })
    },

    detailsItems(item) {
      const {
        name, reference, controlling_status: controllingStatus, cpf, vacation, commission, valor_vehicles: valorVehicles, total, total_vehicles_emplacamento: totalVehiclesEmplacamento,
      } = item

      this.openModal = true
      this.vendor_name = name
      this.reference = reference
      this.statusSales = controllingStatus
      this.vendor_cpf = cpf
      this.vacation = vacation
      this.commission = commission
      this.totalSales = Number(valorVehicles)
      this.numberSales = total
      this.registrationTotal = totalVehiclesEmplacamento
    },

    async handleUpdatedTableWithFilter() {
      this.isLoadingTableData = true
      try {
        const response = await axiosIns.post('/api/v1/integration/contract/vendor_region', this.bodyFilter)

        // await this.getReference()

        this.itemsTable = response.data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoadingTableData = false
      }
    },

    updatedTable() {
      this.getListTargetVendors()

      this.handleUpdatedTableWithFilter()

      console.log(this.bodyFilter)
    },

    handleMonthSelection() {
      this.dateMenu = false
    },

    clearFilter() {
      this.itemsTable = []
      this.itemSearch = ''
      this.itemSearchDate = ''
      this.itemSearchStatus = ''
      this.companyNameSelected = ''
    },

    /**
     * @param {string} value
     * @returns {number}
     */
    removeSpecialCharactersFromCommission(value) {
      const removeCharactersRegex = /[^\d]/g
      const BrazilianStandardValueFormat = Number(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })

      return Number(BrazilianStandardValueFormat.replace(removeCharactersRegex, ''))
    },

    /**
     * formatted name for avatar icon
     *
     * @param {string} name
     * @returns the first two letters of the name
     */
    avatarText(name) {
      if (!name) return ''

      const nameArray = name.split(' ')
      const first = nameArray[0]
      const last = nameArray[nameArray.length - 1]

      const newName = [first, last]

      return newName.map(word => word.charAt(0).toUpperCase()).join('')
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns formatted date
     */
    formatDate(date) {
      if (!date) return null

      const [year, month] = date.split('-')

      return `${month}/${year}`
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({ name }) => {
        const employee = name.toLowerCase()

        return employee.includes(filterDataLower)
      })
    },
  },
}
</script>
<style scoped>
.container-vacation{
  display: flex;
  align-items: center;
}

.vacation{
  width: 60px;
  height: 30px;
  background: #16B1FF1A;
  border-radius: 50px;
  color: #16B1FF;
  font-weight: bold;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
